import React from "react";
import Foot from "./Foot";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import "./question.css";
import background from "./img/Ellipse.png";

export default function Privacy() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      <section
        style={{
          backgroundImage: `url(${background}) `,
        }}
      >
        <div className="container h-100 pb-5">
          <div className="row d-flex justify-content-center align-items-center h-50">
            <div className="">
              <div
                className="card shadow-sm w-100"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body ">
                  <img
                    onClick={() => navigate("/")}
                    src="./assets/LeftArrow.svg"
                    alt="leftArrow"
                    className="mr-3"
                    style={{ cursor: "pointer" }}
                  />
                  <h2
                    className="card-title text-center"
                    style={{ color: "#00A5D8", fontWeight: "bold" }}
                  >
                    WEBSITE PRIVACY POLICY (NIGERIA DATA PROTECTION REGULATION,
                    2019)
                  </h2>
                  <div className="accordion">
                    <h5>
                      INTRODUCTION <br />
                    </h5>
                    <div className="text-justify">
                      Robert and John Limited (‘’R&J”) respects your privacy and
                      is committed to protecting your personal data. This
                      privacy policy will inform you as to how we look after
                      your personal data when you interact with the Ductour
                      Website (“Website”, “website”) and App (the “App”, “")
                      (regardless of where you visit it from) and tell you about
                      your privacy rights and how the law protects you. This
                      website is not intended for children and we do not
                      knowingly collect data relating to children. <br /> <br />{" "}
                      This privacy policy supplements other notices and privacy
                      policies and is not intended to override them.
                      <br /> <br />
                    </div>
                    <h5>
                      1. IMPORTANT INFORMATION AND WHO WE ARE <br />
                    </h5>
                    <div>
                      CONTROLLER <br />
                      R&J is the controller and responsible for your personal
                      data collectively referred to as “R&J’’, “we”, “us” or
                      “our” in this privacy policy. <br />
                      We have appointed a Data Protection Officer (DPO) who is
                      responsible for overseeing questions in relation to this
                      privacy policy. If you have any questions about this
                      privacy policy, including any requests to exercise your
                      legal rights, please contact the DPO using the details set
                      out below.
                    </div>
                    <br /> <br />
                    <div>
                      <h5>
                        CONTACT DETAILS <br />
                      </h5>
                      If you have any questions about this privacy policy or our
                      privacy practices, please contact our DPO in the following
                      ways: Full name of legal entity: [DETAILS] <br />
                      Email address: [DETAILS] <br />
                      Postal address: [DETAILS]
                      <br />
                      [Telephone number: [DETAILS]]
                      <br />
                      You have the right to make a complaint at any time to the
                      National Information Technology Development Agency
                      (NITDA), Nigeria’s supervisory authority for data
                      protection issues (nitda.gov.ng/). We would, however,
                      appreciate the chance to deal with your concerns before
                      you approach NITDA so please contact us in the first
                      instance.
                      <br />
                      <br />
                    </div>
                    <div>
                      <h5>
                        CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US
                        OF CHANGES{" "}
                      </h5>
                      We keep our privacy policy under regular review. It is
                      important that the personal data we hold about you is
                      accurate and current. Please keep us informed if your
                      personal data changes during your relationship with us.
                    </div>{" "}
                    <br />
                    <br />
                    <h5>
                      THIRD-PARTY LINKS <br />
                    </h5>
                    <div>
                      This Website and App may include links to third-party
                      websites, plug-ins and applications. Clicking on those
                      links or enabling those connections may allow third
                      parties to collect or share data about you. We do not
                      control these third-party websites and are not responsible
                      for their privacy statements. When you leave our website,
                      we encourage you to read the privacy policy of every
                      website you visit.
                    </div>{" "}
                    <br />
                    <br />
                    <h5>1. CONSENT</h5> <br />
                    <div>
                      You accept this Privacy Policy when you give consent upon
                      access to our Website or App or use our services, content,
                      features, technologies or functions offered on our Website
                      or App. This Policy governs the use of R&J’s Website and
                      App by our users and subscribers unless otherwise agreed
                      through written contract. We may amend this Privacy Policy
                      at any time by posting a revised version on our Website or
                      App. The revised version will be effective 7 (seven) days
                      after publication.{" "}
                    </div>
                    <br />
                    <br />
                    <h5>
                      1. THE DATA WE COLLECT ABOUT YOU <br />{" "}
                    </h5>
                    <div>
                      Personal data, or personal information, means any
                      information about an individual from which that person can
                      be identified. It does not include data where the identity
                      has been removed (anonymous data).
                      <br />
                      We may collect, use, store and transfer different kinds of
                      personal data about you which we have grouped together as
                      follows:
                      <br />
                      Identity Data includes [first name, maiden name, last
                      name, username or similar identifier, marital status,
                      title, date of birth and gender].
                      <br />
                      Contact Data includes [delivery address, email address and
                      telephone numbers].
                      <br />
                      Technical Data includes [internet protocol (IP) address,
                      your login data, browser type and version, time zone
                      setting and location, browser plug-in types and versions,
                      operating system and platform, and other technology on the
                      devices you use to access this website].
                      <br />
                      Profile Data includes [your username and password,
                      preferences, feedback and survey responses].
                      <br />
                      Usage Data includes [information about how you use our
                      website, products and services].
                      <br />
                      Marketing and Communications Data includes [your
                      preferences in receiving marketing from us and our third
                      parties and your communication preferences].
                      <br />
                      Medical Data including [Health related information on your
                      diagnosis, test and medication from the Healthcare
                      Providers and from your interaction with the Doctors]
                      <br />
                      We also collect, use and share Aggregated Data such as
                      statistical or demographic data for any purpose.
                      Aggregated Data could be derived from your personal data
                      but is not considered personal data in law as this data
                      will not directly or indirectly reveal your identity. For
                      example, we may aggregate your Usage Data to calculate the
                      percentage of users accessing a specific website feature.
                      However, if we combine or connect Aggregated Data with
                      your personal data so that it can directly or indirectly
                      identify you, we treat the combined data as personal data
                      which will be used in accordance with this privacy policy.
                      <br />
                      We do not collect any Special Categories of Personal Data
                      about you (this includes details about your race or
                      ethnicity, religious or philosophical beliefs, sexual
                      orientation, political opinions, trade union membership,).
                      Nor do we collect any information about criminal
                      convictions and offences.
                      <br />
                    </div>
                    <h5>
                      IF YOU FAIL TO PROVIDE PERSONAL DATA <br />{" "}
                    </h5>
                    <div>
                      Where we need to collect personal data by law, or under
                      the terms of a contract we have with you, and you fail to
                      provide that data when requested, we may not be able to
                      perform the contract we have or are trying to enter into
                      with you. In this case, we may have to cancel a service
                      you have with us, but we will notify you if this is the
                      case at the time.
                      <br />
                      <br />
                    </div>
                    <h5>
                      1. HOW IS YOUR PERSONAL DATA COLLECTED? <br />
                    </h5>
                    <div>
                      We use different methods to collect data from and about
                      you including through: Direct interactions. You may give
                      us your [Identity, Contact and Financial Data] by filling
                      in forms or by corresponding with us by post, phone, email
                      or otherwise. This includes personal data you provide when
                      you: apply for our services: create an account on our app
                      or website; or give us feedback or contact us. Automated
                      technologies or interactions. As you interact with our
                      website, we will automatically collect Technical Data
                      about your equipment, browsing actions and patterns. We
                      collect this personal data by using cookies, server logs
                      and other similar technologies. We may also receive
                      Technical Data about you if you visit other websites
                      employing our cookies. Please see our cookie policy at
                      ductour.com/cookiespolicyfor further details. Third
                      parties or publicly available sources. We will receive
                      personal data about you from various third parties [and
                      public sources] as set out below Technical Data from the
                      following parties:
                      <br />
                      (a) analytics providers such as Google based outside
                      Nigeria;
                      <br />
                      (b) advertising networks] based inside or outside Nigeria;
                      and
                      <br />
                      (c) search information providers based inside or outside
                      Nigeria
                      <br />
                      Contact, Financial and Transaction Data from providers of
                      technical, payment and delivery services] based [inside OR
                      outside] Nigeria. Identity and Contact Data from data
                      brokers or aggregators] based [inside OR outside]
                      Nigeria]. Medical Data from [the Healthcare Providers and
                      the Doctors]
                      <br />
                      <br />
                    </div>
                    <h5>
                      5.HOW WE USE YOUR PERSONAL DATA <br />{" "}
                    </h5>
                    <div>
                      We will only use your personal data when the law allows us
                      to. Most commonly, we will use your personal data in the
                      following circumstances: If you have consented to our
                      processing of your data by interacting with the Website
                      and/or the App Where we need to perform the contract, we
                      are about to enter into or have entered into with you.
                      Where it is necessary for our legitimate interests (or
                      those of a third party) and your interests and fundamental
                      rights do not override those interests. Where we need to
                      comply with a legal obligation. Generally, we do not rely
                      on consent as a legal basis for processing your personal
                      data although we will get your consent before sending
                      third party direct marketing communications to you via
                      email or text message. You have the right to withdraw
                      consent to marketing at any time by contacting us.
                      <br />
                      <br />
                    </div>
                    <h5>
                      1. PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA{" "}
                      <br />{" "}
                    </h5>
                    <div>
                      We have set out below, in a table format, a description of
                      all the ways we plan to use your personal data, and which
                      of the legal bases we rely on to do so. We have also
                      identified what our legitimate interests are where
                      appropriate. Note that we may process your personal data
                      for more than one lawful ground depending on the specific
                      purpose for which we are using your data. Please contact
                      us if you need details about the specific legal ground, we
                      are relying on to process your personal data where more
                      than one ground has been set out in the table below.{" "}
                      <br />
                    </div>
                    {/** <div className="card">
                    <div className="card-body">
                    <div className="table-responsive">
                      <table className="table  table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <h5>Purpose/Activity</h5>
                              </div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>
                                <h5>Type of data</h5>
                              </div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>
                                <h5>
                                  Lawful basis for processing including basis of
                                  legitimate interest
                                </h5>
                              </div>

                              <div>&nbsp;</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                To manage our relationship with you which will
                                include:
                              </div>

                              <div>
                                (a) Notifying you about changes to our terms or
                                privacy policy
                              </div>

                              <div>
                                (b) Asking you to leave a review or take a
                                survey
                              </div>

                              <div>&nbsp;</div>

                              <div>(c) verify your identity&nbsp;</div>

                              <div>&nbsp;</div>

                              <div>
                                (d) Process your applications
                                for&nbsp;&nbsp;provision of healthcare services
                                with HealthCare Providers, telemedicine, and
                                send notices about your transactions to the
                                relevant parties
                              </div>

                              <div>&nbsp;</div>

                              <div>&nbsp;</div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>(a) Identity</div>

                              <div>(b) Contact</div>

                              <div>(c) Profile</div>

                              <div>(d) Marketing and Communications</div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>(a) Performance of a contract with you</div>

                              <div>
                                (b) Necessary to comply with a legal obligation
                              </div>

                              <div>
                                (c) Necessary for our legitimate interests (to
                                keep our records updated and to study how
                                customers use our products/services)
                              </div>

                              <div>&nbsp;</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                To administer and protect our business and this
                                website (including troubleshooting, data
                                analysis, testing, system maintenance, support,
                                reporting and hosting of data)
                              </div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>(a) Identity</div>

                              <div>(b) Contact</div>

                              <div>(c) Technical</div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>
                                (a) Necessary for our legitimate interests (for
                                running our business, provision of
                                administration and IT services, network
                                security, to prevent fraud and in the context of
                                a business reorganisation or group restructuring
                                exercise)
                              </div>

                              <div>
                                (b) Necessary to comply with a legal obligation
                              </div>

                              <div>&nbsp;</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                To deliver relevant website content and
                                advertisements to you and measure or understand
                                the effectiveness of the advertising we serve to
                                you
                              </div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>(a) Identity</div>

                              <div>(b) Contact</div>

                              <div>(c) Profile</div>

                              <div>(d) Usage</div>

                              <div>(e) Marketing and Communications</div>

                              <div>(f) Technical</div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>
                                Necessary for our legitimate interests (to study
                                how customers use our products/services, to
                                develop them, to grow our business and to inform
                                our marketing strategy)
                              </div>

                              <div>&nbsp;</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                To use data analytics to improve our website,
                                products/services, marketing, customer
                                relationships and experiences
                              </div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>(a) Technical</div>

                              <div>(b) Usage</div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>
                                Necessary for our legitimate interests (to
                                define types of customers for our products and
                                services, to keep our website updated and
                                relevant, to develop our business and to inform
                                our marketing strategy)
                              </div>

                              <div>&nbsp;</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                To make suggestions and recommendations to you
                                about goods or services that may be of interest
                                to you
                              </div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>(a) Identity</div>

                              <div>(b) Contact</div>

                              <div>(c) Technical</div>

                              <div>(d) Usage</div>

                              <div>(e) Profile</div>

                              <div>(f) Marketing and Communications</div>

                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div>
                                Necessary for our legitimate interests (to
                                develop our products/services and grow our
                                business)
                              </div>

                              <div>&nbsp;</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>
                                Engaging with the Healthcare Providers and the
                                Doctors on Live DUCTOUR
                              </div>
                            </td>
                            <td>
                              <div>Medical Data</div>
                            </td>
                            <td>
                              <div>Performance of our contract with you.</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      </div>
                    </div>{" "}*/}
                    <br />
                    <br />
                    <h5>
                      OPTING OUT <br />{" "}
                    </h5>
                    <div>
                      You can ask us or third parties to stop sending you
                      marketing messages at any time by: logging into the
                      website and checking or unchecking relevant boxes to
                      adjust your marketing preferences; following the opt-out
                      links on any marketing message sent to you; or contacting
                      us at any time]. Where you opt out of receiving these
                      marketing messages, this will not apply to personal data
                      provided to us as a result of [a product/service purchase,
                      warranty registration, product/service experience or other
                      transactions].
                    </div>
                    <br />
                    <br />
                    <h5>
                      COOKIES <br />
                    </h5>
                    <div>
                      You can set your browser to refuse all or some browser
                      cookies, or to alert you when websites set or access
                      cookies. If you disable or refuse cookies, please note
                      that some parts of this website may become inaccessible or
                      not function properly. For more information about the
                      cookies we use, please see [ductour.com/cookiespolicy].]
                    </div>
                    <br />
                    <h5>
                      {" "}
                      <br />
                      CHANGE OF PURPOSE{" "}
                    </h5>
                    <div>
                      We will only use your personal data for the purposes for
                      which we collected it, unless we reasonably consider that
                      we need to use it for another reason and that reason is
                      compatible with the original purpose. If you wish to get
                      an explanation as to how the processing for the new
                      purpose is compatible with the original purpose, please
                      contact us. If we need to use your personal data for an
                      unrelated purpose, we will notify you and we will explain
                      the legal basis which allows us to do so. Please note that
                      we may process your personal data without your knowledge
                      or consent, in compliance with the above rules, where this
                      is required or permitted by law.
                    </div>
                    <br />
                    <br />
                    <h5>
                      7. DISCLOSURES OF YOUR PERSONAL DATA <br />
                    </h5>
                    <div>
                      We may share your personal data with the parties set out
                      below for the purposes set out in the table [‘’Purposes
                      for which we will use your personal data’’] above.
                      Internal Third Parties as set out in the [Glossary].
                      External Third Parties as set out in the [Glossary].
                      Specific third parties listed in the table above Third
                      parties to whom we may choose to sell, transfer or merge
                      parts of our business or our assets. Alternatively, we may
                      seek to acquire other businesses or merge with them. If a
                      change happens to our business, then the new owners may
                      use your personal data in the same way as set out in this
                      privacy policy. We require all third parties to respect
                      the security of your personal data and to treat it in
                      accordance with the law. We do not allow our third-party
                      service providers to use your personal data for their own
                      purposes and only permit them to process your personal
                      data for specified purposes and in accordance with our
                      instructions.
                    </div>
                    <br />
                    <br />
                    <h5>
                      8. INTERNATIONAL TRANSFERS <br />
                    </h5>
                    <div>
                      We do not transfer your personal data outside Nigeria. If
                      Transfers out of the Country occurs and will involve
                      transferring your data outside Nigeria, we will ensure
                      that your personal data is protected by requiring all our
                      companies to follow the same rules when processing your
                      personal data. We will only transfer your personal data to
                      countries that have been deemed to provide an adequate
                      level of protection for personal data by the National
                      Information Technology Development Agency (“NITDA”). Where
                      we use certain service providers, we may use specific
                      contracts which give personal data the same protection it
                      has in Nigeria.
                    </div>
                    <br />
                    <br />
                    <h5>
                      9. DATA SECURITY <br />{" "}
                    </h5>
                    <div>
                      We have put in place appropriate security measures to
                      prevent your personal data from being accidentally lost,
                      used or accessed in an unauthorised way, altered or
                      disclosed. In addition, we limit access to your personal
                      data to those employees, agents, contractors and other
                      third parties who have a business need to know. They will
                      only process your personal data on our instructions, and
                      they are subject to a duty of confidentiality. We have put
                      in place procedures to deal with any suspected personal
                      data breach and will notify you and any applicable
                      regulator of a breach where we are legally required to do
                      so.
                    </div>{" "}
                    <br />
                    <br />
                    <h5>
                      10. DATA RETENTION <br />{" "}
                    </h5>
                    <div>
                      HOW LONG WILL YOU USE MY PERSONAL DATA FOR? We will only
                      retain your personal data until the earlier of (a) the
                      fulfilment of the purposes we collected it for, (b) the
                      withdrawal of your consent on which the processing is
                      based (subject to other provisions of this policy); (c)
                      any objection by to the processing in the absence of
                      overriding legitimate grounds for the processing; (d) the
                      erasure of the same for compliance with a legal obligation
                      in Nigeria. To determine the appropriate retention period
                      for personal data, we consider the amount, nature and
                      sensitivity of the personal data, the potential risk of
                      harm from unauthorised use or disclosure of your personal
                      data, the purposes for which we process your personal data
                      and whether we can achieve those purposes through other
                      means, and the applicable legal, regulatory, tax,
                      accounting or other requirements.
                    </div>{" "}
                    <br />
                    <br />
                    <h5>
                      11. YOUR LEGAL RIGHTS <br />{" "}
                    </h5>
                    <div>
                      Under certain circumstances, you have rights under data
                      protection laws in relation to your personal data to:
                      Request access to your personal data. Request correction
                      of your personal data. Request erasure of your personal
                      data. Object to processing of your personal data. Request
                      restriction of processing your personal data. Request
                      transfer of your personal data. Right to withdraw consent.
                      If you wish to exercise any of the rights set out above,
                      please contact us. You have the right to pursue all
                      available remedies under the NDPR where you fear violation
                      of the privacy policy. We try to address any of your
                      concerns and remedy any alleged violation within three
                      months. It could take us longer if your request depending
                      on the surrounding circumstances. In this case, we will
                      notify you and keep you updated accordingly. Subject to
                      applicable law, we shall not be liable for any violations
                      arising out of your negligence, carelessness, or other
                      wilful act or misconduct or omissions. NO FEE USUALLY
                      REQUIRED You may not have to pay a fee to access your
                      personal data (or to exercise any of the other rights).
                      However, we may charge a reasonable fee if your request is
                      clearly unfounded, repetitive or excessive. Alternatively,
                      we could refuse to comply with your request in the
                      foregoing circumstances. WHAT WE MAY NEED FROM YOU We may
                      need to request specific information from you to help us
                      confirm your identity and ensure your right to access your
                      personal data (or to exercise any of your other rights).
                      This is a security measure to ensure that personal data is
                      not disclosed to any person who has no right to receive
                      it. We may also contact you to ask you for further
                      information in relation to your request to speed up our
                      response. TIME LIMIT TO RESPOND We try to respond to all
                      legitimate requests within one month. Occasionally it
                      could take us longer than a month if your request is
                      particularly complex or you have made a number of
                      requests. In this case, we will notify you and keep you
                      updated.
                    </div>{" "}
                    <br />
                    <br />
                    <h5>
                      12. GLOSSARY <br />{" "}
                    </h5>
                    <div>
                      Legitimate Interest means the interest of our business in
                      conducting and managing our business to enable us to give
                      you the best service/product and the best and most secure
                      experience. We make sure we consider and balance any
                      potential impact on you (both positive and negative) and
                      your rights before we process your personal data for our
                      legitimate interests. We do not use your personal data for
                      activities where our interests are overridden by the
                      impact on you (unless we have your consent or are
                      otherwise required or permitted to by law). You can obtain
                      further information about how we assess our legitimate
                      interests against any potential impact on you in respect
                      of specific activities by contacting us. Performance of
                      Contract means processing your data where it is necessary
                      for the performance of a contract to which you are a party
                      or to take steps at your request before entering into such
                      a contract. Comply with a legal obligation means
                      processing your personal data where it is necessary for
                      compliance with a legal obligation that we are subject to.
                      Healthcare Providers means a hospital or clinic registered
                      with R&J to provide the limited and specified medical
                      services. Doctor means a licensed, accredited or certified
                      physician or healthcare practitioner who is registered
                      with R&J to provide telemedicine services on Live Ductour.
                      THIRD PARTIES INTERNAL THIRD PARTIES Other R& J’s parent
                      company and other companies in the group acting as joint
                      controllers or processors and who are based in Nigeria and
                      provide IT and system administration services and
                      undertake leadership reporting. EXTERNAL THIRD PARTIES
                      Service providers [acting as processors] based outside
                      Nigeria who provide IT and system administration
                      services]. Healthcare Providers and Doctors registered
                      with R&J. Professional advisers acting as processors or
                      joint controllers including lawyers, bankers, auditors and
                      insurers based outside Nigeria who provide [consultancy,
                      banking, legal, insurance and accounting services].
                      Regulators and other authorities [acting as processors or
                      joint controllers] based in Nigeria who require reporting
                      of processing activities in certain circumstances].
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Foot />
    </>
  );
}
