import React from "react";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";

function Home() {
  return (
    <div className="container-fluid">
      <Header />
      <Body/>
      <Footer/>
    </div>
  );
}

export default Home;
