import React from "react";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <>
      {" "}
      <nav
        id="top"
        className="navbar navbar-expand-lg navbar-light ml-3 mr-3 pt-3"
      >
        <Link to="/" className="navbar-brand">
          <img
            className="d-inline-block align-top"
            src="./assets/DuctourLogo.png"
            width="65"
            height="85"
            alt="Logo"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ">
            <li className="nav-item mr-4">
              <Link
                to="/"
                className="nav-link"
                style={{ color: "#000000", fontSize: "20px" }}
                href="#top"
              >
                Home
              </Link>
            </li>
            <li className="nav-item mr-4">
              <a
                className="nav-link"
                style={{ color: "#000000", fontSize: "20px" }}
                href="#offering"
              >
                About
              </a>
            </li>

            <li className="nav-item mr-4">
              <a
                className="nav-link"
                style={{ color: "#000000", fontSize: "20px" }}
                href="#about"
              >
                Offerings
              </a>
            </li>

            <li className="nav-item mr-4">
              <a
                className="nav-link"
                style={{ color: "#000000", fontSize: "20px" }}
                href="#contact"
              >
                Contact
              </a>
            </li>

            <li className="nav-item mr-4">
              <button type="button" className="btn btn-primary mt-1">
                <a
                  href="#DownloadApp"
                  style={{ color: "#000000", fontSize: "20px" }}
                >
                  Download App
                </a>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
