import React, { useState } from "react";
import Foot from "./Foot";
import "./question.css";
import Swal from "sweetalert2";

// import { useForm } from "react-hook-form";

export default function Footer() {
  // const { register, handleSubmit, reset } = useForm();
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setemail] = useState("");

  const [message, setmessage] = useState("");
  // comments are here
  const submitDetails = async (e) => {
    e.preventDefault();
    setfirstName("");
    setlastName("");
    setphoneNumber("");
    setemail("");
    setmessage("");
    const response = await fetch("https://ductour.com/api/enquiry", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        firstName,
        lastName,
        phoneNumber,
        email,
        message,
      }),
    });
    const resData = await response.json();
    if (resData.code === 201) {
      setfirstName("");
      setlastName("");
      setphoneNumber("");
      setemail("");
      setmessage("");
      Swal.fire({
        title: "Success",
        icon: 'successs',
        type: "success",
        text: "You have subscribed successfully.",
      });
    } else if (resData.code !== 201) {
      Swal.fire({
        title: "Error",
        icon: 'error',
        type: "error",
        text: "Please try again.",
      });
    }
  };

  return (
    <>
      <section>
        <div id="contact" className=" container text-center pt-5 pb-5">
          <h1 style={{ color: "#7C7C7C" }}>Get In Touch With Us</h1>
          <p style={{ color: "#676767" }}>Feel Free to Drop A Message Below</p>

          <form className="mx-auto" onSubmit={submitDetails}>
            <div
              style={{ position: "relative" }}
              className="container input-group  form-group d-none d-md-block d-lg-block"
            >
              <div className="row">
                <input
                  required
                  type="text"
                  value={firstName}
                  onChange={(e) => setfirstName(e.target.value)}
                  style={{ borderRadius: "36px", background: "#F3F4F4" }}
                  className="form-control border border-0 col-12 col-md-12 col-lg-6"
                  placeholder="First Name"
                  name="firstName"
                />
                <input
                  required
                  type="text"
                  style={{
                    borderRadius: "36px",
                    background: "#F3F4F4",
                  }}
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                  name="name"
                  className="form-control border border-0 input-group-input col-12 col-md-12 col-lg-6"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="form-group d-md-none d-lg-none d-sm-block">
              <input
                style={{ borderRadius: "36px", background: "#F3F4F4" }}
                required
                type="text"
                name="name"
                value={firstName}
                onChange={(e) => setfirstName(e.target.value)}
                className="form-control border border-0"
                id="exampleInputFrName"
                aria-describedby="FrNameHelp"
                placeholder="First Name"
              />
            </div>

            <div className="form-group d-md-none d-lg-none d-sm-block">
              <input
                style={{ borderRadius: "36px", background: "#F3F4F4" }}
                required
                type="text"
                name="name"
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
                className="form-control border border-0"
                id="exampleInputLnName"
                aria-describedby="LnNameHelp"
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <input
                style={{ borderRadius: "36px", background: "#F3F4F4" }}
                required
                type="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                className="form-control border border-0"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Email"
                name="email"
              />
            </div>
            <div className="form-group">
              <input
                style={{ borderRadius: "36px", background: "#F3F4F4" }}
                required
                type="number"
                value={phoneNumber}
                onChange={(e) => setphoneNumber(e.target.value)}
                className="form-control border border-0"
                name="number"
                id="exampleInputPassword1"
                placeholder="Phone Number"
              />
            </div>
            <div className="form-group">
              <textarea
                required
                type="text"
                style={{
                  borderRadius: "36px",
                  background: "#F3F4F4",
                  height: "154px",
                }}
                value={message}
                onChange={(e) => setmessage(e.target.value)}
                name="message"
                className="form-control border border-0"
                placeholder="Message"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn"
              style={{
                width: "200px",
                background: "#2893D0",
                color: "white",
                borderRadius: "30px",
                cursor: "pointer",
                marginTop: "30px",
              }}
            >
              Submit
            </button>
          </form>
          <div
            id="DownloadApp"
            className="text-center"
            style={{ paddingTop: "35px" }}
          >
            <p
              className=" d-lg-inline d-md-inline"
              style={{
                color: "#545454",
                fontStyle: "normal",
                fontWeight: "normal",
              }}
            >
              Download DUCTOUR for free
            </p>
            <div className=" d-none d-md-inline d-lg-inline mx-3 my-2 d-lg-inline d-md-inline">
              <a
                className="mr-3 btn google-btn-footer  shadow"
                href="https://play.google.com/store/apps/details?id=com.ductour&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
                role="button"
                style={{ backgroundColor: "#E4E4E4" }}
              >
                <img
                  src="./assets/Google-Play-Prism.png"
                  alt=""
                  height="30"
                  className="pr-1 "
                />
                <span style={{ padiingTop: "10px" }}>Google Play</span>
              </a>
              <a
                role="button"
                className=" btn  apple-btn-footer  shadow"
                href="https://apps.apple.com/mg/app/ductour/id1522014188"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: "#E4E4E4" }}
              >
                <img
                  src="./assets/apple-mac-vector-logo.png"
                  alt=""
                  height="30"
                  className="pr-1 "
                />
                iOS
              </a>
            </div>

            <small className="d-md-none d-lg-none d-sm-block">
              <a
                className="pr-3"
                href="https://play.google.com/store/apps/details?id=com.ductour&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="button" className="btn btn-outline-light shadow">
                  <img
                    src="./assets/Google-Play-Prism.png"
                    alt=""
                    height="20"
                  />
                </button>
              </a>

              <a href="https://apps.apple.com/mg/app/ductour/id1522014188" target="_blank" rel="noopener noreferrer">
                <button type="button" className="btn btn-outline-light shadow">
                  <img
                    src="./assets/apple-mac-vector-logo.png"
                    alt=""
                    height="20"
                  />
                </button>
              </a>
            </small>
          </div>
        </div>
      </section>

      <Foot />
    </>
  );
}
