import React, { useState, useEffect } from "react";
import background from "./img/man-bg.png";
import { data } from "../data/data";
import { motion } from "framer-motion/dist/es/index";

export default function Body() {
  const [Infodata, setData] = useState([]);
  useEffect(() => {
    setData(data);
  }, []);
  return (
    <>
      <section id="offering" className="mb-5">
        <h2 className="text-center h2-heading">
          Affordable Healthcare for Our People
        </h2>
        <div
          className=" embed-responsive embed-responsive-16by9 d-none d-md-block d-lg-block w-50"
          style={{
            borderRadius: "30px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <iframe
            id="video"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/2mAiI7DwxBs"
            allowFullScreen
            title="DUCTOUR Yotube video"
          />
        </div>

        <div
          className=" embed-responsive embed-responsive-16by9 d-sm-block d-md-none d-lg-none"
          style={{
            borderRadius: "30px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <iframe
            id="video2"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/2mAiI7DwxBs"
            allowFullScreen
            title="DUCTOUR Yotube video"
          />
        </div>
      </section>

      <section
        style={{ backgroundImage: `url(${background})`, display: "block" }}
        className="text-center img-style"
      >
        <h2 className=" h2-heading text-center dont mt-5 pt-5">DUCTOUR</h2>
        <p
          style={{ fontWeight: "lighter", fontSize: "33px", color: "#676767" }}
        >
          Delivering Unrelenting Care To Our Users Reliably
        </p>
        <div id="about" className="container text-center flip-grid">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 ">
            {Infodata.map((d, index) => {
              return (
                <div key={index} className="col pt-5 pb-5">
                  <div className=" flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img
                          className="mt-5"
                          src={d.image}
                          alt={d.title}
                          style={{ width: "50px", height: "60px" }}
                        />
                        <p>{d.title}</p>
                      </div>
                      <div className="flip-card-back">
                        <p className="text-center pt-5">{d.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="text-center">
        <p
          className="mt-3 mb-5"
          style={{ fontSize: "38px", color: "#ED1E79", fontWeight: 700 }}
        >
          Sign Up And Receive
        </p>

        <div className="container pb-5">
          <div className="card-deck style-card">
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="card card-bottom"
            >
              <div className="card-body">
                <img
                  src="./assets/DUCID.png"
                  width="100"
                  height="80"
                  alt="DUC ID"
                />
                <h5 className="h5-style pt-4">DUC ID</h5>
                <p className="card-text" style={{ fontSize: "20px" }}>
                  Your Personal Identification
                  <br /> Number Used for Hospital <br /> Visits and Registration
                </p>
              </div>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="card card-bottom "
            >
              <div className="card-body">
                <img
                  width="100"
                  height="80"
                  src="./assets/DucMeal.png"
                  alt="DUC ID"
                />
                <h5 className="h5-style pt-4">DUC Meal</h5>
                <p className="card-text" style={{ fontSize: "20px" }}>
                  Enjoy A Free Meal at <br /> Any of Our Partner <br />{" "}
                  Restaurants
                </p>
              </div>
            </motion.div>
          </div>
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://onelink.to/ductour"
        >
          <button
            className="btn mb-4 btn-bottom text-center"
          >
            <span style={{ fontSize: "20px", fontWeight: "lighter" }}>
              Activate
            </span>
          </button>
        </a>
      </section>
    </>
  );
}
