import React from "react";
import "../global.css";
import background from "./img/Ellipse.png";
import NavBar from "./NavBar";

export default function Header() {
  return (
    <section>
      <div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeIn", duration: 0.5 }}
        className="vector-accross"
        style={{ position: "relative" }}
      >
        <img
          className="d-none d-sm-none d-md-none d-lg-block"
          src="./assets/Vector-Across.png"
          alt="Vector"
          style={{
            position: "absolute",
            top: 0,
            width: "1800px",
            height: "600px",
            left: "-20px",
          }}
        />
      </div>

      <NavBar />

      <div className="container ">
        <section>
          <div className="row">
            <div
              className="col-6 d-none d-md-block d-lg-block text-center"
              style={{ marginTop: "20px" }}
            >
              <div
                className="text-start text-justify text-style"
                style={{ letterSpacing: "0.1em" }}
              >
                <h1 className="h1-heavy " style={{ fontSize: "2.9rem" }}>
                  Don’t Die of
                </h1>
                <h1 className="h1-heavy " style={{ fontSize: "2.9rem" }}>
                  Treatable Diseases
                </h1>
                <h4 className="h5-heavy " style={{ fontSize: "35px" }}>
                  Get DUCTOUR
                </h4>
              </div>

              <div className="row pt-4 button-right">
                <a href="#video" className="Inner-Learn">
                  <button className="btn btn-lg btn-outline-info mr-3 mt-2 Learn-More d-none d-lg-inline d-md-inline">
                    <span style={{ fontSize: "18px" }}>Learn More</span>
                  </button>
                </a>

                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://onelink.to/ductour"
                    className="text-light"
                  >
                    <button className="btn btn-lg Signup-Top shadow-lg mt-2 d-none px-0 d-lg-inline d-md-inline">
                      <span style={{ fontSize: "18px" }}> Get Started </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 ">
              <div
                className=" text-center  "
                style={{
                  backgroundImage: `url(${background})`,
                }}
              >
                <img
                  src="assets/picture-change.gif"
                  alt="changing-assets"
                  className="img-fluid"
                  width="510"
                  height="700"
                />
              </div>
              <div className="d-md-none d-lg-none d-sm-block bg-sm-muted">
                <div className="text-center">
                  <h1 className="h1-heavy" style={{ fontSize: "35px" }}>
                    Don’t Die of Treatable Diseases
                  </h1>
                  <h4 className="h5-heavy" style={{ fontSize: "20px" }}>
                    Get DUCTOUR
                  </h4>
                </div>
                <div className="text-center">
                  <a href="#video2" className="Inner-Learn">
                    <button className="btn btn-outline-info mobile-learn text-muted ">
                      Learn More
                    </button>
                  </a>
                </div>
                <div className="text-center">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://onelink.to/ductour"
                    className="text-light"
                  >
                    <button className="btn mobile-signup mt-4 shadow ">
                      Get Started
                    </button>
                  </a>
                </div>
                <div className="text-center">
                  <p className="">Download DUCTOUR Today</p>
                </div>
                <div className="text-center  ">
                  <small className="pb-3">
                    <a
                      className="pr-3"
                      href="https://play.google.com/store/apps/details?id=com.ductour&hl=en&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        type="button"
                        className="btn btn-outline-light shadow"
                      >
                        <img
                          src="./assets/Google-Play-Prism.png"
                          alt=""
                          height="20"
                        />
                      </button>
                    </a>

                    <a
                      href="https://apps.apple.com/mg/app/ductour/id1522014188"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        type="button"
                        className="btn btn-outline-light shadow"
                      >
                        <img
                          src="./assets/apple-mac-vector-logo.png"
                          alt=""
                          height="20"
                        />
                      </button>
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="row">
        <div className="col-md-6 d-none d-md-block d-lg-block text-center">
          <p style={{ fontSize: "25px", fontWeight: "lighter" }}>
            Download DUCTOUR for free
          </p>
          <a
            className="mr-3 btn google-btn btn-outline-light shadow"
            href="https://play.google.com/store/apps/details?id=com.ductour&hl=en&gl=US"
            target="_blank"
            rel="noopener noreferrer"
            role="button"
          >
            <img
              src="./assets/Google-Play-Prism.png"
              alt=""
              className="pr-2"
              height="30"
            />
            Google Play
          </a>
          <a
            role="button"
            className=" btn  apple-btn btn-outline-light shadow"
            href="https://apps.apple.com/mg/app/ductour/id1522014188"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assets/apple-mac-vector-logo.png"
              alt=""
              height="30"
              className="pr-2"
            />
            iOS
          </a>
        </div>
      </div>
    </section>
  );
}
