import React from "react";
import Foot from "./Foot";
import background from "./img/Ellipse.png";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import "./question.css";
import { faqData } from "../data/data";
import Accordion from "./Accordion";

export default function Questions() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      <section
        style={{
          backgroundImage: `url(${background}) `,
        }}
      >
        <div className="container h-100 pb-5">
          <div className="row d-flex justify-content-center align-items-center h-50">
            <div className="">
              <div
                className="card shadow-sm w-100"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body ">
                  <img
                    onClick={() => navigate("/")}
                    src="./assets/LeftArrow.svg"
                    alt="leftArrow"
                    className="mr-3"
                    style={{cursor:'pointer'}}
                  />
                  <h2
                    className="card-title text-center"
                    style={{ color: "#00A5D8", fontWeight: "bold" }}
                  >
                    Frequently Asked Questions
                  </h2>
                  {/**<div
                    className="form-group input-group row justify-content-center"
                    style={{ position: "relative" }}
                  >
                    <div className="col-md-8">
                      <input
                        type="search"
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#FBFBFB",
                        }}
                        className="form-control border border-0"
                        placeholder="Email"
                      />
                    </div>
                      </div>**/}
                  <div className="accordion">
                    {faqData.map(({ id, header, message }) => (
                      <Accordion key={id} header={header} message={message} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Foot />
    </>
  );
}
