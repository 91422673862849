import React, { useState } from "react";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";

export default function Accordion({ id, header, message }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion-item pb-3">
      <div
        className="accordion-title border border-0"
        onClick={() => setIsActive(!isActive)}
      >
        <div>{header}</div>
        <div>{isActive ? <BsChevronDown /> : <BsChevronRight />}</div>
      </div>
      {isActive && <div className="accordion-content">{message}</div>}
    </div>
  );
}
