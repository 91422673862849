import React, { useState } from "react";
import {
  BsInstagram,
  BsFacebook,
  // BsLinkedin,
  // BsTwitter,
  BsFillTelephoneFill,
  BsYoutube,
} from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { motion } from "framer-motion/dist/es/index";

export default function Foot() {
  const [subscribeEmail, setsubscribeEmail] = useState("");

  const submitMail = async (e) => {
    e.preventDefault();
    setsubscribeEmail("");

    const response = await fetch(" https://ductour.com/api/subscribe", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        subscribeEmail,
      }),
    });
    const resData = await response.json();
    if (resData.code === 201) {
      setsubscribeEmail("");
      Swal.fire({
        title: "Success",
        icon: "success",
        type: "success",
        text: "You have subscribed successfully.",
      });
    } else if (resData.code !== 201) {
      Swal.fire({
        title: "Error",
        icon: "error",
        type: "error",
        text: "Please try again.",
      });
    }
  };

  return (
    <>
      {" "}
      <footer>
        <div style={{ backgroundColor: "#F8F8F8" }}>
          <div className="container pb-5">
            <div className="row">
              <div className="col-md d-lg-inline d-md-inline">
                <div style={{ color: "#898989", paddingTop: "60px" }}>
                  <a
                    style={{ color: "#898989" }}
                    href="https://www.instagram.com/ductour/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <BsInstagram
                      className="mr-3"
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </a>

                  {/**<BsTwitter
                    className="mr-3"
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  /> */}
                  <a
                    style={{ color: "#898989" }}
                    href="https://m.facebook.com/Ductour-103651291903650/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsFacebook
                      className="mr-3"
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </a>

                  <a
                    style={{ color: "#898989" }}
                    href="https://www.youtube.com/channel/UCjctpEyvsAjHw1h98Qlw4AQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsYoutube
                      className="mr-3"
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </a>

                  <a
                    style={{ color: "#898989" }}
                    href="mailto:@gmail.com?Subject=Some%20subject"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillMail
                      className="mr-3"
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </a>
                  <a
                    style={{ color: "#898989" }}
                    href="tel:+2349040003535"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsFillTelephoneFill
                      className="mr-3"
                      style={{ cursor: "pointer", fontSize: "30px" }}
                    />
                  </a>
                </div>
                <div
                  style={{
                    color: "#898989",
                    paddingTop: "20px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://randjltd.com"
                  >
                    randjltd.com
                  </a>
                </div>
              </div>
              <div className="col-md">
                <p
                  style={{
                    fontWeight: "lighter",
                    paddingTop: "20px",
                    fontStyle: "normal",
                    fontSize: "15px",
                    color: "#545454",
                  }}
                >
                  Subscribe to our Newletter
                </p>
                <form onSubmit={submitMail}>
                  <div
                    className="input-group mb-3"
                    style={{ position: "relative" }}
                  >
                    <input
                      required
                      id="emailSub"
                      type="email"
                      style={{ borderRadius: "30px", background: "#F3F4F4" }}
                      className="form-control border border-0"
                      onChange={(e) => setsubscribeEmail(e.target.value)}
                      placeholder="Email"
                    />

                    <span className="input-group-btn">
                      <motion.button
                        whileClick={{ scale: 0.3 }}
                        type="submit"
                        style={{
                          borderRadius: "20px",
                          position: "absolute",
                          right: 0,
                          width: "150px",
                          background: "#2893D0",
                          zIndex: 999,
                          top: 0,
                        }}
                        className="btn btn-secondary"
                      >
                        Subscribe
                      </motion.button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ background: "#E9E9E9", height: "30px" }}>
          <article className="col-lg-6 col-md-6  col-sm-0 d-lg-block d-none pl-4">
            <small
              style={{
                color: "#000000",
                fontSize: "lighter",
                fontWeight: "bold",
                paddingLeft: "180px",
              }}
            >
              {" "}
              &copy; Robert & John 2022
            </small>{" "}
          </article>
          <article className=" col-lg-6 col-md-6 col-sm-12 pl-4 text-center">
            <span>
              <Link to="/terms">
                <small
                  style={{
                    color: "#000000",
                    fontSize: "lighter",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Terms and conditions
                </small>
              </Link>
            </span>{" "}
            <span>
              <Link to="/privacy">
                <small
                  style={{
                    color: "#000000",
                    fontSize: "lighter",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  className="px-2"
                >
                  Privacy
                </small>
              </Link>
            </span>{" "}
            <span>
              <Link to="/questions">
                <small
                  style={{
                    color: "#000000",
                    fontSize: "lighter",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  className="px-2"
                >
                  FAQs
                </small>
              </Link>
            </span>{" "}
          </article>
        </div>
      </footer>
    </>
  );
}
