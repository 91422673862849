export const data = [
  {
    id: 1,
    image: "./assets/HospitalLogo.png",
    title: "Hospital Visit",
    description: "Choose from over 1,000 hospitals nationwide",
  },
  {
    id: 2,
    image: "./assets/RobotDoctor.png",
    title: "Check Your Symptoms",
    description:
      "Don’t google your symptoms, chat with DUCTOUR, our AI symptom checker",
  },
  {
    id: 3,
    image: "./assets/LiveDoctor.png",
    title: "Access Specialists Worldwide",
    description: "Consult with medical specialists worldwide",
  },
  {
    id: 4,
    image: "./assets/HealthTips.png",
    title: "Health Tips",
    description:
      "Health tips on the go! Access to videos, podcasts and articles on how to improve your health",
  },
  {
    id: 5,
    image: "./assets/Cashless.png",
    title: "Cashless Hospital Visits",
    description:
      "Visit any hospital, see a doctor, get treated, don’t bother about payment",
  },
  {
    id: 6,
    image: "./assets/DucMeal.png",
    title: "DUC Meal",
    description: "Get a free meal after registration",
  },
];

export const faqData = [
  {
    id: 1,
    header: "What services do I get for buying DUCTOUR?",
    message:
      "You get (1) access to a hospital of your choice, (2) at that hospital you get doctor consultation, lab test, and treatment for Malaria, Common cold/Flu, Typhoid, Diarrhoea/Dysentery, Minor injuries and Skin conditions, and (3) four times a year.",
  },
  {
    id: 2,
    header: "What does DUCTOUR PAC cover?",
    message:
      "Doctor consultation, lab test and treatment of Malaria, Common cold/Flu, Typhoid, Diarrhoea/Dysentery, Minor injuries, and Skin conditions.",
  },
  {
    id: 3,
    header: "What is not covered?",
    message: "Medication for any ailment not specified in the list above.",
  },
  {
    id: 4,
    header: "What type of treatment will I get from the hospital?",
    message: "Basic medication and wound dressings for the covered ailments.",
  },
  {
    id: 5,
    header: "What happens if I have an illness that is not covered?",
    message:
      "If you know that your illness is not covered, please do not attempt to use My PAC. If you do not know whether your illness is covered, you can use My PAC to consult your doctor. If your doctor concludes that your illness is not covered, your doctor will inform you accordingly. ",
  },
  {
    id: 6,
    header: "Who is covered?",
    message: "The enrollee is covered. The PAC is not transferable.",
  },
  {
    id: 7,
    header: "For how long can I use My PAC?",
    message:
      "My PAC is valid from the start date and remains valid for one year or so long as you have available visits, whichever is shorter.",
  },
  {
    id: 8,
    header: "How many times can I visit the hospital in a year?",
    message:
      "You can visit the hospital 4 times. Any additional visits will require a paid top-up",
  },
  {
    id: "9",
    header: "What happens if I use up all my visits?",
    message:
      "If you use up your visits not less than 1 month from the expiration date of My PAC, you can purchase additional visits through top-up.",
  },
  {
    id: 10,
    header: "Is my family covered?",
    message:
      "Your family is not covered in your plan. Each family member will require his or her own PAC",
  },
  {
    id: 11,
    header: "Can my friends and family use my PAC?",
    message: "Friends and family cannot use your PAC",
  },
  {
    id: 12,
    header: "How can I cover my family?",
    message:
      "You can initiate individual plans for up to 4 children (below 18 years) through your phone. On the homepage of your application, select HELP PAC, then choose type. Insert the number of person and Select Child- then follow the instructions.) Note that the registered telephone number must be available during all hospital visits. Note also that all PAC activated through you will expire on the date your plan expires.",
  },
  {
    id: 13,
    header: "Can I use the same phone number to register my children?",
    message:
      "Yes, you can use the same phone number to register your children, but we require separate phone numbers to register your spouse, extended family, and friends.",
  },
  {
    id: 14,
    header: "Can I buy a PAC for my extended family?",
    message:
      "Yes, you can purchase a PAC for your extended family. You can purchase plans for more than four people (above 18 years) through your phone. On the homepage of your application, - select HELP PAC and SELECT either APP USER, OR SMS USER and INSERT THE NUMBER OF PERSON then follow the instructions",
  },
  {
    id: 15,
    header: "Can I buy a PAC for my friends and employees?",
    message:
      "Yes, you can Purchase a PAC for Your friends and employees, but they will need to access the service directly through their individual phones.",
  },
  {
    id: 16,
    header: "How do I pick a hospital?",
    message:
      "When you initiate My PAC, you will be prompted to select a hospital from the list of available hospitals.",
  },
  {
    id: 17,
    header: "Are the listed hospitals accredited?",
    message: "Yes, the listed hospitals are accredited.",
  },
  {
    id: 18,
    header: "Can I change my hospital?",
    message:
      "Yes, you can change your hospital within the validity of your plan.",
  },
  {
    id: 19,
    header: "How soon after I change my hospital can I visit the new hospital?",
    message:
      "You can visit the new hospital 24 hours after you change the hospital",
  },
  {
    id: 20,
    header: "How many times can I change my hospital?",
    message:
      "You can change your hospital up to three times within the validity of your plan.",
  },
  {
    id: 21,
    header: "Can I go to a hospital that is not in my area?",
    message:
      "You can go to a hospital that is not in your area so long as you select the hospital 24 hours before your visit.",
  },
  {
    id: 22,
    header: "How do I pay for My PAC?",
    message:
      "You can pay for my PAC within the application through debit/credit card or direct debit from your account.",
  },
  {
    id: 23,
    header: "Are there any payment plans available?",
    message: "There are no payment plans available currently.",
  },
  {
    id: 24,
    header: "How do I receive my DUC MEAL code?",
    message:
      "You are entitled to a free DUC MEAL once you have paid for a PAC within the application.",
  },
  {
    id: 25,
    header: "How do I activate health cover?",
    message:
      "To activate health cover, sign-up for the service; select the MY PAC module, click Start then select a hospital from the drop-down list provided to you",
  },
  {
    id: 26,
    header: "When will my PAC be activated?",
    message: "My PAC is activated once you click Start",
  },
  {
    id: 27,
    header: "What is OTP?",
    message: "One-time password received during registration.",
  },
  {
    id: 28,
    header: "How do I get my OTP?",
    message:
      "You receive your OTP when signing up for the DUCTOUR App. You will have to insert your active phone number to receive an OTP for registration. Note OTP will not be received if you have previously registered that phone number in the DUCTOUR App.",
  },
  {
    id: 29,
    header: "What do I do when I get sick?",
    message:
      "When you get sick, visit your selected hospital. When you get to the hospital, give the attending staff your DUC ID with a valid ID CARD. After doctor consultation but BEFORE lab test and receipt of your medication, generate OTP and give it to the attending staff.",
  },
  {
    id: 30,
    header: "Where is my DUC ID",
    message:
      "Your DUC ID is in your Profile - the blue human image at the top right of the application.",
  },
  {
    id: 31,
    header: "What is a unique code?",
    message:
      "Unique code is a code that can ONLY be generated in the hospital after seeing a medical doctor. Unique code represents the visit you have. Four unique codes for four visits.",
  },
  {
    id: 32,
    header: "How do I generate a unique code?",
    message: "Click the icon located in the bottom right of your DUCTOUR App",
  },
  {
    id: 33,
    header: "What do I do with the unique code?",
    message:
      "After your doctor consultation but before lab test and receipt of medication, click on the Unique code icon to generate a code. Give the code generated to the hospital admin staff.",
  },
  {
    id: 34,
    header: "Which hospital do I go to?",
    message:
      "Go to your selected hospital; the hospital stated in your application.",
  },
  {
    id: 35,
    header:
      "Interacting with the hospital or clinic, what should I do when I get to the hospital?",
    message:
      "When you get to the hospital, show the attending staff your DUC ID with a valid ID card",
  },
  {
    id: 36,
    header: "What type of treatment will I get from the hospital?",
    message:
      "You will get doctor consultation, lab tests and medication for the covered ailments.",
  },
  {
    id: 37,
    header: "What happens if I have an illness that is not covered in my PAC?",
    message:
      "You will consult the doctor and perform lab tests. If the result of the lab tests indicates an ailment that is not covered, you will be notified. If you opt to receive treatment at this point, you will be asked to pay for that treatment. In this case, the lab test will be covered (you will not pay for it) and the visit shall count as 1 of your Visits.",
  },
  {
    id: 38,
    header: "Will I see a doctor?",
    message:
      "Yes, you will see a general doctor; this is included in your Health PAC.",
  },
  {
    id: 39,
    header: "Will the hospital give me medicine?",
    message:
      "Yes, the hospital will give you medicine for the covered ailments.",
  },
  {
    id: 40,
    header: "What do I do if the hospital does not attend to me?",
    message:
      "If you encounter issues, call the customer care number in your DUCTOUR App",
  },
  {
    id: 41,
    header: "What if I run out of medication?",
    message:
      "The medication provided by the hospital will cover the full treatment plan. If you do not recover from the ailment, please return to the hospital.",
  },
  {
    id: 42,
    header: "What if I do not recover after treatment?",
    message:
      "If you do not recover after treatment, please return to the hospital.",
  },
  {
    id: 43,
    header: "Is my follow-up treatment at the hospital covered?",
    message:
      "Follow-up hospital visits done within two weeks of the initial hospital visit are covered by one unique code. Treatment for a different ailment or any visit done after the follow up period shall require a new unique code.",
  },
  {
    id: 44,
    header: "What do I do if I have a complaint?",
    message: "Contact DUCTOUR through your application",
  },
  {
    id: 45,
    header: "How can I top up?",
    message: "To top-up click the Top-Up button in my pac module.",
  },
  {
    id: 46,
    header: "How many hospital visits does a top-up cover?",
    message:
      "The Top-up will give the recommended number of visits. Within the first 9 months of your health cover, you can top-up as many times as you want to increase the number of visits.",
  },
  {
    id: 47,
    header: "How many times can I top-up in a year?",
    message:
      "You can top up as many times as you want within the first 9 months of your health cover. From the 10th month, the application will prompt you to renew your PAC.",
  },
  {
    id: 48,
    header: "How do I renew my PAC?",
    message:
      "The application will prompt you to renew. Once you see the prompt, click the button, and follow the instructions.",
  },
  {
    id: 49,
    header: "What should I do in an emergency?",
    message: "In an emergency, go to your selected hospital.",
  },
  {
    id: 50,
    header: "What is the second opinion?",
    message:
      "Second opinion is an artificial intelligence, interactive symptom checker that prompts you to enter information and symptoms; and then provides a suggestion based on the informational database. Do not rely on Second opinion; it is not a doctor’s diagnosis. If you feel unwell, you are advised to visit your selected hospital or your family doctor.",
  },
  {
    id: 51,
    header: "How do I use the second opinion?",
    message: "Open the Second opinion module and begin chat.",
  },
  {
    id: 52,
    header: "Can I depend on what my second opinion tells me?",
    message:
      "No, you cannot depend on what the second opinion tells you; it is not a doctor’s diagnosis. If you feel unwell, you are advised to visit your selected hospital or your family doctor.",
  },
  {
    id: 53,
    header: "What are health tips?",
    message:
      "Health tips are general information on healthy lifestyle. This is provided in the form of articles, videos and audio",
  },
  {
    id: 54,
    header: "How do I view health tips?",
    message:
      "On the bottom right of your application, select Health tips icon and scroll through the available articles, videos and audio.",
  },
  {
    id: 55,
    header: "How to create your password?",
    message:
      "On the top right of your application, click on the blue human image to access your profile and scroll down to logout. Then a POP UP with password will appear. Insert your chosen password and click on submit.",
  },
  {
    id: 56,
    header: "How can I retrieve my password?",
    message:
      "On the sign-in page of the Ductour app. You will find below a forgot password link, click on it - then follow the instructions.",
  },
  {
    id: 57,
    header: "Is Robert and John an HMO?",
    message:
      "We are an electronic administrator that ensures that all our beneficiaries access quality healthcare delivery and services reliably",
  },
  {
    id: 58,
    header: "How is the OTP collected?",
    message:
      "The User generates the OTP by clicking on the Unique Code. Note that the OTP will be generated after the user’s doctor’s consultation but before performing the Lab test and dispensing prescribed medication.",
  },
  {
    id: 59,
    header:
      "Who is responsible for Services and Medications that are not covered on the PAC and Tariff?",
    message:
      "After Consultation, if the user’s symptoms rule out covered ailments, the doctor shall inform the user that the ailment is not covered. If the user opts to continue treatment at this point, the user will be responsible for payment for further services, including lab test, and medication. DUCTOUR will pay for consultation only. After Consultation and a covered lab test, if covered ailments have been ruled out, the doctor shall inform the user that the ailment is not covered. If the user opts to continue treatment at this point, the user will be responsible for payment for further services, including any other lab tests, and medication. DUCTOUR will pay for consultation and lab test only.",
  },
  {
    id: 60,
    header: "The Health PAC is not comprehensive.",
    message:
      "Our PAC was created to focus on the most common ailments that affect over 140 million Nigerians. We will continue to add to the PAC as demand grows.",
  },
];
