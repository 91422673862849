import Home from "./Components";
import Questions from "./Components/Questions";
import { Routes, Route } from "react-router-dom";
import Terms from "./Components/Terms";
import Privacy from "./Components/Privacy";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="questions" element={<Questions />} />
      <Route exact path="terms" element={<Terms />} />
      <Route exact path="privacy" element={<Privacy />} />
    </Routes>
  );
}

export default App;
