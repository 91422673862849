import React from "react";
import Foot from "./Foot";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import "./question.css";
import background from "./img/Ellipse.png";

export default function Terms() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      <section
        style={{
          backgroundImage: `url(${background}) `,
        }}
      >
        <div className="container h-100 pb-5">
          <div className="row d-flex justify-content-center align-items-center h-50">
            <div className="">
              <div
                className="card shadow-sm w-100"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body ">
                  <img
                    onClick={() => navigate("/")}
                    src="./assets/LeftArrow.svg"
                    alt="leftArrow"
                    className="mr-3"
                    style={{ cursor: "pointer" }}
                  />
                  <h2
                    className="card-title text-center"
                    style={{ color: "#00A5D8", fontWeight: "bold" }}
                  >
                    Terms And Conditions
                  </h2>
                  <div className="accordion">
                    <h5>
                      1. Overview <br />{" "}
                    </h5>
                    <div className="text-justify">
                        Robert and John Limited (‘’R&J”) respects your privacy and
                      is committed to protecting your personal data. This
                      privacy policy will inform you as to how we look after
                      your personal data when you interact with the Ductour
                      Website (“Website”, “website”) and App (the “App”, “")
                      (regardless of where you visit it from) and tell you about
                      your privacy rights and how the law protects you. This
                      website is not intended for children and we do not
                      knowingly collect data relating to children. <br /> <br /> This privacy
                      policy supplements other notices and privacy policies and
                      is not intended to override them.
                      <br /> <br />
                      1.2 For the avoidance of doubt, When you use the App, you
                      may access the Health Pac Service (“Health Pac”), Second
                      Opinion Service (“Second Opinion”), the Health Tips
                      Service (“Health Tips”), or the Live DUCTOUR Service
                      (“Live DUCTOUR”), which are all referred to together as
                      the “Services”. The Services also include any updates or
                      additional features, services or tools included on the
                      App. These Terms also cover the DUCTOUR Website (the
                      “Website”) located at www.ductour.com and any updates to
                      the Website. By accessing and using the Service or
                      Services on the App or Website, you agree to be bound by
                      these Terms and Conditions. If you do not agree to, or
                      cannot comply with, these Terms, you should not use the
                      App or Website. You will be deemed to have accepted these
                      terms if you continue to use the App or Website.
                      <br /> <br />
                      1.3 You will be asked to accept these Terms as part of
                      your account registration. These Terms may be updated from
                      time to time and if your rights change you will be
                      notified of the change. If you do not agree to or cannot
                      comply with these Terms and amendments thereto, you should
                      not use the App or Website. <br /> <br />
                    </div>
                    <div>
                      <h5>
                        2. DUCTOUR Service <br />{" "}
                      </h5>
                      2.1 These Terms apply to all the Services and the users of
                      the DUCTOUR Service (i.e. Health Pac, Second Opinion,
                      Health Tips and the Live Ductor). Terms specific to each
                      of the Services are also clearly identified in the
                      following paragraphs. <br /> <br />
                      2.2 Information provided by you through the DUCTOUR
                      Service may contain links to third party websites
                      (particularly in the case of Second Opinion) that are not
                      owned or controlled by R&J. R&J has no control over, and
                      assumes no responsibility for, the content, privacy
                      policies, or practices of any third party websites. In
                      addition, R&J will not and cannot censor or edit the
                      content of any third-party site. By using the Service, you
                      expressly acknowledge and agree that R&J shall not be
                      responsible for any damages, claims or other liability
                      arising from or related to your use of any third-party
                      website. <br /> <br />
                      2.3 Notwithstanding the foregoing, any and all personal
                      information you provide will be held in strict accordance
                      with our Privacy Policy. By using the DUCTOUR App or
                      Website, you consent to such processing and you warrant
                      that all data provided by you is accurate.
                      <br /> <br />
                    </div>
                    <div>
                      <h5>
                        3. Health Pac <br />{" "}
                      </h5>
                      3.1 The Health Pac service is a feature that allows you to
                      receive treatment for itemized specific medical conditions
                      and specific services from a Healthcare Provider (i.e. a
                      hospital or clinic registered with R&J to provide the
                      limited and specified medical services for the purposes of
                      Health Pac Service. <br />
                      <br />
                      <div className="font-weight-bold">
                        {" "}
                        3.2 Benefit Package and Scope of Cover
                        <br />{" "}
                      </div>
                      The specific services available under Health Pac are:
                      <br />
                      3.2.1 Four outpatient visits to a Healthcare Provider per
                      Validity Period (defined in clause 3.4 below) which will
                      cover <br />
                      a. consultation with a general practitioner, <br />
                      b. basic laboratory investigation(s) and provision of
                      diagnosis-specific drugs for the following covered medical
                      conditions (Malaria, Typhoid, Dysentery/Diarrhea, Common
                      Cold, Skin rashes, Eye Infections, Minor Injuries and
                      laceration (maximum of four stitches) and Pre-natal care)
                      <br />
                      The scope of Health Pac is limited to the provisions of
                      this clause 3.2 and shall be available to Users in any
                      part of Nigeria. <br />
                      <br />
                      <div className="font-weight-bold">
                        3.3 The Subscription Process
                        <br />
                      </div>
                      3.3.1 You may subscribe to the Health Pac through the App,
                      Website, USSD Channels provided on the App or Website by
                      providing your biodata and other contact information
                      required for your subscription to the Health Pac service
                      and selecting a Healthcare provider form the preloaded
                      list made available by us (the “Subscription”). On
                      submission of the online form, a PIN is automatically
                      generated to confirm the success of the enrolment.
                      <br />
                      3.3.2 Within two weeks from your enrolment, R&J shall
                      contact the Healthcare Provider for your registration. In
                      the event the Healthcare Provider rejects your
                      registration, R&J shall find a substitute Healthcare
                      Provider in the same geographical location for you. We
                      shall communicate with you if we are unable to find a
                      (replacement) Healthcare Provider within the
                      aforementioned 14 days. If we are unable to get a
                      replacement Healthcare Provider, we will search
                      neighbouring geographical locations until we find one.{" "}
                      <br />
                      3.3.3 The PAC shall become activated not earlier than 14
                      days after the User enrolls on the App (the “onboarding
                      period”). During this onboarding period (or such longer
                      period, where a substitute Healthcare Provider is yet to
                      be appointed), the User cannot access any of the services
                      referred to in 3.2 above. Nevertheless, the User may
                      access the services of a Doctor using Live DUCTOUR. <br />
                      3.3.4 A User will be identified at his or her selected
                      Healthcare Provider by an OTP and a Government-issued
                      personal photo identity card (e.g. national identity card,
                      driver’s license, international passport, permanent
                      voter’s card). <br />
                      <br />
                      <div className="font-weight-bold">
                        3.4 Conditions of Use
                        <br />
                      </div>
                      3.4.1 No one above the age of 60 years old can subscribe
                      to Health Pac. <br />
                      3.4.2 No chronic conditions or in-patient services are
                      covered under the Health Pac.
                      <br />
                      3.4.3 The validity period of a subscription for all
                      Subscribers or Users or Customers which is 12 consecutive
                      calendar months from the date Health Pac is activated on
                      the App (“Validity Period”) is renewable annually.
                      <br />
                      3.4.4 Only one User and not a combination of Users can
                      take the benefit of a subscription during the Validity
                      period. Each Subscription shall not be transferable or
                      assignable by any means. <br />
                      3.4.5 All unutilized benefits shall cease after the
                      Validity Period.
                      <br />
                      3.4.6 You shall not be deemed to have completed a visit or
                      to have utilized a benefit under clause 3.2 above where
                      the appropriate tests are not conducted and/or required
                      medication or drugs are not made available to the User. In
                      such a case, You shall contact R&J’s customer care
                      channels immediately to report such an issue. In such
                      instance, you shall be entitled to retrieve such visit on
                      the App and an OTP earlier provided to the Healthcare
                      Provider by you shall be canceled and the visit credited
                      back to your account. <br />
                      3.4.7 Subject to clause 3.4.11 below, a follow-up visit
                      for a particular medical condition that is done within two
                      weeks of the initial consultation shall not be counted as
                      one of the four consultations provided that the visit
                      occurs at the same facility and for the same condition
                      first consulted for. An OTP will not be required for such
                      follow-up visit.
                      <br />
                      3.4.8 You shall be responsible for the cost of additional
                      tests, drugs and other procedures that are outside the
                      scope of the cover and as prescribed by the Healthcare
                      Providers. <br />
                      3.4.9 In the event of co-morbidity i.e. where you are
                      diagnosed as having two covered conditions co-existing at
                      the same time, the User will be treated for such
                      co-morbidity and will be deemed to have utilized two
                      visits out of his total number of visits. <br />
                      3.4.10 Where you are diagnosed with a condition(s) that is
                      outside the scope of covered benefits, R&J will pay for
                      consultation and where conducted, the laboratory test. You
                      shall not be entitled to receive further benefits because
                      the condition is not covered under the PAC. For the
                      avoidance of doubt, and notwithstanding any other
                      provision of this Terms, we will not be responsible for
                      paying for your drugs or procedure (in the case of
                      injury), or for any medical condition that is outside the
                      scope set out in clause 3.2 above. <br />
                      3.4.11 A condition or conditions that are not diagnosable
                      in one visit are outside the Health Pac. For the avoidance
                      of doubt, and notwithstanding any other provision of this
                      Terms, we will not be responsible for paying for your
                      drugs or procedure (in the case of injury), or for any
                      medical condition that is outside the scope set out in
                      clause 3.2 above. <br />
                      <br />
                    </div>
                    <div>
                      <h5>
                        4. Second Opinion <br />
                      </h5>
                      4.1 Second Opinion is a symptom checker driven by
                      artificial intelligence that gives you suggestions on
                      potential ailments you may possibly be experiencing based
                      on the symptoms entered by you on the App. The output from
                      Second Opinion does not constitute medical advice,
                      diagnosis and/or treatment. You should always talk to a
                      qualified medical professional about any questions you may
                      have about a medical condition. If you think you have a
                      medical emergency, you should see a doctor immediately.
                      Any decisions you make affecting your health should always
                      be made in consultation with a doctor, and not on the
                      basis of our digital healthcare tools. <br />
                      <br />
                      4.2 Second Opinion may present a list of conditions to
                      you. These are listed in the order of how closely the
                      conditions match the symptoms entered, based on symptom
                      prevalence for conditions which are taken from publicly
                      available medical resources. The order in which conditions
                      are listed does not in any way indicate the likelihood of
                      the user having or not having a condition. Second Opinion
                      provides information to you based on information entered
                      and does not diagnose your own health condition or make
                      treatment recommendations for you. Second Opinion is not a
                      substitute for a doctor or other healthcare professional.
                      You shouldn't take or stop taking any action (such as
                      taking medicines) based on information from Second
                      Opinion. <br />
                      <br />
                      4.3 We are not the proprietors of Second Opinion. We make
                      no warranties in relation to (a) the accuracy of the
                      technology Second Opinion relies upon or the expertise of
                      the proprietors or operators of Second Opinion. We are
                      merely providing access to the benefits of Second Opinion.
                      Therefore, we encourage you to read the terms and
                      conditions as well as the privacy policy of Second Opinion
                      as stated in the App. <br />
                      <br />
                      4.4 Please note that, only subscribers to Health Pac can
                      use this service at no extra cost. If you do not subscribe
                      to Health Pac, you shall only be able to use this service
                      on a pay-as-you-go basis.
                      <br />
                      <br />
                    </div>
                    <div>
                      <h5>
                        5. Health Tips <br />{" "}
                      </h5>
                      The Health Tips feature contains simple culturally
                      relevant tips about nutrition, exercise, supplements, and
                      herbal remedies. Health Tips does not constitute medical
                      advice, diagnosis or treatment, it is for information
                      purposes only. Health Tips merely curates these tips and
                      makes them available on the App. You shouldn't take or
                      stop taking any action (such as taking medicines) based on
                      the information published on Health Tips. For the
                      avoidance of doubt, we make no warranties <br /> (i) on
                      the correctness or effectiveness of the tips shared on
                      Health Tips <br /> (ii) on the expertise or genuineness of
                      the source from which those tips were obtained <br />{" "}
                      (iii) in relation to the Health Tips generally. <br /> We
                      therefore advise that you consult your doctor before
                      taking any supplements, using any herbs, changing your
                      eating habits or embarking on any exercise regimen based
                      on the tips provided via the Health Tips feature. <br />
                      <br />
                    </div>
                    <div>
                      <h5>
                        6. Live DUCTOUR. <br />
                      </h5>
                      6.1 You are able to call in to have a live video chat with
                      a medical doctor when you use Live DUCTOUR. The services
                      provided under Live DUCTOUR are limited to diagnosis only.{" "}
                      <br /> <br />
                      6.2 The Medical Doctors (the “Doctor (s)”) who deliver
                      services through the Live DUCTOUR are independent
                      professionals. R&J does not practice medicine or any other
                      licensed profession and does not interfere with the
                      practice of medicine by the Doctors, each of whom is
                      responsible for his or her services and compliance with
                      the requirements of his profession and license. R&J shall
                      not be liable for any professional advice you obtain from
                      any Doctor via the Live DUCTOUR service. <br /> <br />
                      6.3 Please note that, only subscribers to Health Pac can
                      use this service at no extra cost. If you do not subscribe
                      to Health Pac, you shall only be able to use this service
                      on a pay-as-you-go basis.
                      <br /> <br />
                    </div>
                    <div>
                      <h5>
                        7. Website/App Content. <br />
                      </h5>
                      None of the contents of the Website or App (other than
                      information received from the Doctors) should be
                      considered medical advice or an endorsement,
                      representation or warranty that any particular medication
                      or treatment is safe, appropriate or effective for you.{" "}
                      <br /> <br />
                    </div>
                    <div>
                      <h5>
                        8. User Accounts. <br />
                      </h5>
                      8.1 To interact with the Services, you are required to
                      create an account (“Account”) by entering your name, email
                      address, password and other contact information (“Account
                      Information”). To create an Account, you must be 18 years
                      and above. If you are not up to eighteen (18) years, you
                      may not register to use our Services. <br /> <br />
                      8.2 You hereby agree that the Account Information provided
                      by you at all times are accurate, current and complete.
                      You may not transfer or share your Account details with
                      anyone or create more than one Account (except for
                      subaccounts established for the use of children of whom
                      you are the parent or legal guardian). You agree to
                      maintain the confidentiality of your Account and all
                      activities that occur under your Account. R&J reserves the
                      right to take any reasonably necessary action regarding
                      the security of the App or Website and your Account
                      Information. Under no circumstance shall R&J be held
                      liable to you for any liabilities or damages resulting
                      from your use of the App or Website, any of the Services,
                      your use of your Account Information or your release of
                      the Account Information to a third party. You may not use
                      anyone else’s account at any time. <br /> <br />
                    </div>
                    <div>
                      <h5>
                        9. Use of the Services by Children. <br />
                      </h5>
                      Except as otherwise specifically stated in these Terms,
                      the Services are available for use by children, but
                      children are not allowed to become Members and may not use
                      the Services without the supervision of a parent or legal
                      guardian. If you register as the parent or legal guardian
                      on behalf of a child, you shall be responsible for
                      complying with these Terms. <br />
                      <br />
                    </div>
                    <h5>
                      10. Access Rights. <br />
                    </h5>
                    10.1 Subject to your compliance with these Terms, R&J hereby
                    grants you a limited, non-exclusive, nontransferable right
                    to access the DUCTOUR Service and Website solely for your
                    personal and non-commercial use and as permitted under these
                    Terms.
                    <br />
                    <br />
                    10.2 You agree that you will not, and will not attempt to :{" "}
                    <br />
                    (i) use the App or Website or Services to violate any local,
                    state, national, or international law; <br />
                    (ii) impersonate any person or entity, or misrepresent your
                    affiliation with any persons, you will not duplicate,
                    transfer, give access to, copy or distribute any part of the
                    Service in any medium without R&J's prior written
                    authorization; <br />
                    (iii) reverse, engineer, disassemble, decompile, or
                    translate any part of the Service, <br />
                    (iv) knowingly introduce viruses, trojans, worms, logic
                    bombs or other material which is malicious or
                    technologically harmful to or through the App or Website;{" "}
                    <br />
                    (v) by yourself and/or in concert with others attempt to
                    gain unauthorized access to the DUCTOUR Service, the Website
                    and the server on which the Website is stored or any server,
                    computer or database connected to the Website or App; <br />
                    (vii) by yourself and/or in concert with others attack the
                    App or Website via a denial-of-service attack or a
                    distributed denial-of service attack <br /> (vii) otherwise
                    use the Services, App or Website in any manner that exceeds
                    the scope of use granted in these Terms and the incorporated
                    Privacy Policy. <br />
                    <br />
                    10.3 You hereby agree to refrain from the use of abusive,
                    inappropriate language or behavior or unlawful conduct when
                    communicating with Doctors and other Healthcare
                    Professionals for the Services provided through them
                    <br />
                    <br />
                    10.4 both within and outside of the App. R&J shall not be
                    responsible for medical consultations not linked to the
                    Website or App. <br />
                    <br />
                    <h5>
                      11. Fees and Purchase Terms <br />
                    </h5>
                    11.1 You agree that you will pay for the Services provided
                    by R&J, and that R&J may charge your Debit Card, Credit Card
                    or any form of internet account for any Services accessed on
                    the DUCTOUR App or Website and for any additional amounts
                    (including any taxes and late fees, as applicable) that may
                    be accrued by or in connection with your account. You are
                    responsible for the timely payment of all fees and for
                    providing R&J with a valid Credit Card or Debit Card account
                    details for payment of all fees. All fees will be billed to
                    the Credit Card or Debit Card account you designate during
                    the subscription. <br />
                    <br />
                    11.2 If you want to designate a different Credit Card or if
                    there is a change in your Credit Card or Debit Card account
                    status, you must change your information online in the
                    account information section of the application or software.
                    Do note that this may temporarily disrupt your access to the
                    Services while R&J verifies your new payment information.
                    R&J reserves the right to refuse or limit any order for
                    DUCTOUR Services placed by you, where such orders are by its
                    sole judgment capable of harming its business interests.{" "}
                    <br />
                    <br />
                    11.3 Use of the DUCTOUR Service requires compatible devices,
                    internet access, and certain software or operating systems
                    (fees may apply). The Service may also require periodic
                    updates; and may be affected by the performance of these
                    factors. High-speed Internet access is strongly recommended
                    for regular use and is required for video. You are
                    responsible for making all arrangements necessary for you to
                    have access to the App and Website. From time to time, we
                    may restrict access to some parts of the DUCTOUR Service
                    including some parts of the App, Website, or the entire
                    Website, to users who have registered with us. If you
                    choose, or you are provided with, a user identification
                    code, password or any other piece of information as part of
                    our security procedures, you must treat such information as
                    confidential, and you must not disclose it to any third
                    party. We have the right to disable any user identification
                    code or password, whether chosen by you or allocated by us,
                    at any time, if in our opinion you have failed to comply
                    with any of the provisions of these Terms.
                    <br />
                    <br />
                    <h5>
                      12. Intellectual Property Right
                      <br />
                    </h5>
                    The design of the DUCTOUR Service along with R&J created
                    text, scripts, graphics, interactive features and the like
                    and the trademarks, service marks and logos contained
                    therein ("Marks"), are owned by or licensed to R&J, subject
                    to copyright and other intellectual property rights under
                    Nigerian and foreign laws and international conventions. The
                    Service is provided to you AS IS for your information and
                    personal use only. R&J reserves all rights not expressly
                    granted in and to the Service. <br />
                    <br />
                    <h5>
                      13. Warranty <br />
                    </h5>
                    13.1 R&J does not guarantee, represent, or warrant that your
                    use of the DUCTOUR Service and Website (together the
                    “DUCTOUR Products”) will be uninterrupted or error-free, and
                    you agree that from time to time R&J may remove the DUCTOUR
                    Products for indefinite periods of time, or cancel the
                    DUCTOUR Products at any time, without notice to you. You
                    agree that your use of the DUCTOUR Products shall be at your
                    sole risk. to the fullest extent permitted by law, R&J its
                    parent company, subsidiaries, affiliates, officers,
                    directors, employees, and agents disclaim all warranties,
                    express or implied, in connection with the service and your
                    use thereof. <br />
                    <br />
                    13.2 R&J makes no warranties or representations about the
                    accuracy or completeness of the contents of the DUCTOUR
                    Products and assumes no liability or responsibility for any
                    (i) errors, mistakes, or inaccuracies of content, (ii)
                    personal injury or property damage, of any nature
                    whatsoever, resulting from your access to and use of our
                    service, (iii) any unauthorized access to or use of our
                    servers and/or any and all personal information and/or
                    financial information stored therein, (iv) any interruption
                    or cessation of transmission to or from our service, (iv)
                    any bugs, viruses, trojan horses, or the like which may be
                    transmitted to or through our service through the actions of
                    any third party, and/or (v) any errors or omissions in any
                    content or for any loss or damage of any kind incurred as a
                    result of the use of any content posted, emailed,
                    transmitted, or otherwise made available via the DUCTOUR
                    Products. <br />
                    <br />
                    13.3 R&J does not warrant, endorse, guarantee, or assume
                    responsibility for any product or service advertised or
                    offered by a third party through the DUCTOUR Products or any
                    hyperlinked website or featured anywhere herein or other
                    advertising, and R&J will not be a party to or in any way be
                    responsible for monitoring any transaction between you and
                    third-party providers of products or services. as with the
                    purchase of a product or service through any medium or in
                    any environment, you should use your best judgment and
                    exercise caution where appropriate.
                    <br />
                    <br />
                    <h5>
                      14. Limitation of Liability <br />
                    </h5>
                    14.1 In no event shall R&J, its parent company,
                    subsidiaries, affiliates, officers, directors, employees, or
                    agents, be liable to you for any direct, indirect,
                    incidental, special, punitive, or consequential damages
                    whatsoever resulting from any (i) errors, mistakes, or
                    inaccuracies of the content of the DUCTOUR Products, (ii)
                    personal injury or property damage, of any nature
                    whatsoever, resulting from your access to and use of our
                    service, (iii) any unauthorized access to or use of our
                    secure servers and/or any and all personal information
                    and/or financial information stored therein, (iv) any
                    interruption or cessation of transmission to or from our
                    servers, (iv) any bugs, viruses, trojan horses, or the like,
                    which may be transmitted to or through our service by any
                    third party, (v) any errors or omissions in any content or
                    for any loss or damage of any kind incurred as a result of
                    your use of any content posted, emailed, transmitted, or
                    otherwise made available via the service whether based on
                    warranty, contract, tort, or any other legal theory, and
                    whether or not you or R&J, is advised of the possibility of
                    such damages, and/or (vi) the disclosure of information
                    pursuant to these Terms or privacy policy. The foregoing
                    limitation of liability shall apply to the fullest extent
                    permitted by law in the applicable jurisdiction.
                    <br />
                    <br />
                    14.2 R&J shall use reasonable efforts to protect information
                    submitted by you in connection with the DUCTOUR Products,
                    but you agree that your submission of such information is at
                    your sole risk, and R&J hereby disclaims any and all
                    liability to you for any loss or liability relating to such
                    information in any way. <br />
                    <br />
                    14.3 R&J does not represent or guarantee that the DUCTOUR
                    Products will be free from loss, corruption, attack,
                    viruses, interference, hacking, or other security intrusion,
                    and R&J disclaims any liability relating thereto. <br />
                    <br />
                    14.4 DUCTOUR Products is controlled and offered by R&J from
                    its facilities in the Federal Republic of Nigeria. R&J makes
                    no representations that the DUCTOUR Service is appropriate
                    or available for use in other locations. Those who access or
                    use the DUCTOUR Service from other jurisdictions do so at
                    their own volition and are responsible for compliance with
                    local law. <br />
                    <br />
                    14.5 Subject to applicable law, you and R&J agree that any
                    cause of action arising out of or related to the service
                    must commence within one (1) year after the cause of action
                    accrues. Otherwise, such cause of action is permanently
                    barred. <br />
                    <br />
                    <h5>
                      15. Indemnity <br />
                    </h5>
                    15.1 You agree to defend, indemnify and hold harmless R&J,
                    its parent corporation, subsidiaries, affiliates, officers,
                    directors, employees and agents, from and against any and
                    all claims, damages, obligations, losses, liabilities, costs
                    or debt, and expenses (including but not limited to
                    attorney's fees) arising from: (i) your use of and access to
                    the DUCTOUR Products; (ii) your violation of any term of
                    these Terms; (iii) your violation of any third party right,
                    including without limitation any copyright, property, or
                    privacy right; or (iv) any claim that one of your
                    communications caused damage to a third party. <br />
                    <br />
                    15.2 This means that you cannot sue or recover any damages
                    from R&J, its parent corporation, subsidiaries, affiliates
                    directors, officers, employees, affiliates, agents,
                    contractors, and licensors as a result of its decision to
                    remove or refuse to process any information or content, to
                    warn you, to suspend or terminate your access to the DUCTOUR
                    Products, or to take any other action during the
                    investigation of a suspected violation or as a result of R&J
                    's conclusion that a violation of this Terms has occurred.
                    This waiver and indemnity provision applies to all
                    violations described in or contemplated by this agreement.
                    This defense and indemnification obligation will survive
                    these Terms and Conditions and your use of the DUCTOUR
                    Products. <br />
                    <br />
                    <h5>
                      16. Assignment <br />{" "}
                    </h5>
                    These Terms and Conditions, and any rights and licenses
                    granted hereunder, may not be transferred or assigned by
                    you, but may be assigned by R&J without restriction.
                    <br />
                    <br />
                    <h5>
                      17. Suspension and Termination <br />{" "}
                    </h5>
                    We will determine, in our reasonable discretion, whether
                    there has been a breach of these Terms through your use of
                    the Service. When a breach of these Terms has occurred, we
                    may take such action as we deem appropriate. Failure to
                    comply with these Terms constitutes a material breach of the
                    Terms upon which you are permitted to use the Service, and
                    may result in our taking any or all action we deem
                    appropriate, including, but not limited to:- <br />
                    <br />
                    17.1 immediate, temporary or permanent withdrawal of your
                    right to use the DUCTOUR Products; <br />
                    <br />
                    17.2 immediate, temporary or permanent removal of any
                    posting or material uploaded by you to the DUCTOUR Products;{" "}
                    <br />
                    <br />
                    17.3 issuance of a warning to you;
                    <br />
                    <br />
                    17.4 legal proceedings being taken against you for the
                    reimbursement of any reasonable costs that we incur
                    (including, but not limited to, reasonable administrative
                    and legal costs) as a direct result of you failing to abide
                    by the Terms; <br />
                    <br />
                    17.5 pursuit of further legal action against you; and <br />
                    <br />
                    17.6 disclosure of such information to law enforcement
                    authorities as we reasonably feel is necessary.
                    <br />
                    <br />
                    <h5>
                      18. General Conditions <br />{" "}
                    </h5>
                    18.1 You agree that: (i) the DUCTOUR Products shall be
                    deemed solely based in the Federal Republic of Nigeria; (ii)
                    the DUCTOUR Products shall be deemed a passive server that
                    does not give rise to personal jurisdiction over R&J and
                    DUCTOUR, either specific or general, in jurisdictions other
                    than the Federal Republic of Nigeria; and (iii) that you
                    agree to subject to the jurisdiction of the Federal Republic
                    of Nigeria in the event of any legal dispute. <br />
                    <br />
                    18.2 These Terms and Conditions shall be governed by the
                    internal substantive laws of the Federal Republic of
                    Nigeria, without respect to its conflict of laws principles.
                    Any claim or dispute between you and R&J that arises in
                    whole or in part from the Service shall be decided
                    exclusively by a court of competent jurisdiction located in
                    Lagos, Nigeria. <br />
                    <br />
                    18.3 These Terms, together with the Privacy Policy at
                    http://www.ductour.com/privacypolicy and any other legal
                    notices published R&J, including, but not limited to an end
                    user license agreement, shall constitute the entire
                    agreement between you and R&J concerning the Service. <br />
                    <br />
                    18.4 If any provision of these Terms and Conditions is
                    deemed invalid by a court of competent jurisdiction, the
                    invalidity of such provision shall not affect the validity
                    of the remaining provisions of these Terms, which shall
                    remain in full force and effect.
                    <br />
                    <br />
                    18.5 No waiver of any term of this these Terms shall be
                    deemed a further or continuing waiver of such term or any
                    other term, and R&J ‘s failure to assert any right or
                    provision under these Terms and Conditions shall not
                    constitute a waiver of such right or provision.
                    <br />
                    <br />
                    18.6 R&J reserves the right to amend or modify these Terms
                    at any time, and it is your responsibility to review these
                    Terms for any changes. If you do not agree to the Terms,
                    your only recourse is to discontinue the use of the DUCTOUR
                    Products.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Foot />
    </>
  );
}
